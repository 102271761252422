import { getDoc, doc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import calliePic from '../assets/jpg/Callie_image.jpg'
import { db } from '../firebase.config'

const InterventionItem = ({ intervention, id }) => {
  const [docUserRef, setDocUserRef] = useState(null)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const snap = await getDoc(doc(db, 'users', intervention.userRef))

        setDocUserRef(snap.data().name)
      } catch (error) {
        console.log(error)
      }
    }

    fetchUser()
  }, [intervention.userRef])

  return (
    <>
      <div className=' grid grid-cols-3 card card-compact card-borderd bg-white mx-4 my-4'>
        <figure>
          <img
            src={intervention.imgUrls ? intervention.imgUrls[0] : calliePic}
            alt='intervention'
          />
        </figure>

        <div className='card-body m-0 p-0'>
          <h2 className='card-title'>{intervention.name}</h2>
          <p>
            <small>{intervention.description}</small>
          </p>
          <span className='flex'>
            <span>
              <p>
                <small>at: {intervention.time}</small>
              </p>
            </span>
          </span>
        </div>
        <div className='card-actions justify-end  pr-1 '>
          <div>
            <p>
              <small className='text-xs'>notes: {intervention.notes}</small>
            </p>
          </div>
          <div>
            <p>
              <small className='text-xs'>by: {docUserRef}</small>
            </p>
          </div>
        </div>

        {/* <button className='btn btn-primary btn-xs '>Edit</button> */}
      </div>
    </>
  )
}

export default InterventionItem
