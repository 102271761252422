const LatestInterventions = ({ intervention, id }) => {
  // const dt = DateTime(intervention.time)
  // console.log(dt)

  return (
    <div className='card p-1 my-1 bg-white'>
      <div className='flex justify-between'>
        <div className=''>
          <p>
            {intervention.name} =&gt; {intervention.description}
          </p>
        </div>
        <div>
          <div>{intervention.time}</div>
        </div>
      </div>
    </div>
  )
}

export default LatestInterventions
