import {
  collection,
  query,
  where,
  doc,
  getDoc,
  getDocs,
  orderBy
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { ReactComponent as AddIcon } from '../assets/svg/addIcon.svg'
import InterventionItem from '../components/InterventionItem'
import Spinner from '../components/Spinner'
import { DateTime } from 'luxon'
import { db } from '../firebase.config'

const Chart = () => {
  const [interventions, setInterventions] = useState(null)
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState('')

  const params = useParams()

  const dt = DateTime.fromISO(date)

  useEffect(() => {
    const fetchInterventions = async () => {
      try {
        // Get the Chart date
        const chartRef = doc(db, 'charts', params.chartId)
        const chartSnap = await getDoc(chartRef)

        // Get a reference
        const interventionsRef = collection(db, 'interventions')
        // Create a query
        const q = query(
          interventionsRef,
          where('chartRef', '==', params.chartId),
          orderBy('timestamp', 'desc')
        )

        // Exectute query
        const querySnap = await getDocs(q)

        const interventions = []
        const date = chartSnap.data().date

        querySnap.forEach(doc => {
          return interventions.push({
            id: doc.id,
            data: doc.data()
          })
        })

        // interventions.push(querySnap.data.interventions)

        setInterventions(interventions)
        setDate(date)
        setLoading(false)

        // console.log(chartSnap.data())
      } catch (error) {
        console.log(error)
      }
    }

    fetchInterventions()
  }, [params.chartId])

  return (
    <div className='chart p-2 mb-12'>
      <header>
        <p className='pageHeader text-center my-5'>
          {dt.toLocaleString(DateTime.DATE_MED)} Chart
        </p>
      </header>

      <main>
        <div className='card px-2 my-10'>
          <Link
            to={`/add-intervention-item/${params.chartId}`}
            className='btn btn-primary'
          >
            Add Intervention
            <AddIcon fill='#ffffff' />
          </Link>
        </div>

        {loading ? (
          <Spinner />
        ) : interventions && interventions.length > 0 ? (
          <>
            <h3 className='pl-5'>Intervention list</h3>
            <ul>
              {interventions.map(intervention => (
                <InterventionItem
                  key={intervention.id}
                  id={intervention.id}
                  intervention={intervention.data}
                />
              ))}
            </ul>
          </>
        ) : (
          <h3 className='m-20'>No Interventions to display</h3>
        )}
      </main>
    </div>
  )
}

export default Chart
