import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as AddIcon } from '../assets/svg/addIcon.svg'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import { db } from '../firebase.config'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import ChartItem from '../components/ChartItem'

function Charts() {
  const [charts, setCharts] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchCharts = async () => {
      try {
        const q = query(collection(db, 'charts'), orderBy('timestamp', 'desc'))
        const querySnapshot = await getDocs(q)

        const charts = []

        querySnapshot.forEach(doc => {
          return charts.push({
            id: doc.id,
            data: doc.data()
          })
        })

        setCharts(charts)
        setLoading(false)
      } catch (error) {
        toast.error('Could not fetch charts')
      }
    }

    fetchCharts()
  }, [setCharts])

  return (
    <div className='container p-2 mb-24'>
      <header>
        <p className='pageHeader text-center my-5'>Charts by Day</p>
      </header>

      {loading ? (
        <Spinner />
      ) : charts && charts.length > 0 ? (
        <>
          <main>
            <ul className='chartItems'>
              {charts.map(chart => (
                <ChartItem chart={chart.data} id={chart.id} key={chart.id} />
              ))}
            </ul>
          </main>

          <div className='card px-2 m-2'>
            <Link to={`/add-new-chart/`} className='btn btn-primary'>
              Add New Chart
              <AddIcon fill='#ffffff' />
            </Link>
          </div>
        </>
      ) : (
        <>
          <p>No Charts available</p>

          <div className='card px-2 m-2'>
            <Link to={`/add-new-chart/`} className='btn btn-primary'>
              Add New Chart
              <AddIcon fill='#ffffff' />
            </Link>
          </div>
        </>
      )}
    </div>
  )
}

export default Charts
