import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div className='container p-2'>
      <header>
        <p className='pageHeader'>Welcome to Callie's Care!</p>
      </header>

      <div>
        <p>Keep track of Callie's daily progress.</p>
      </div>

      <div>
        <div className='card px-4 my-4'>
          <Link to='sign-up' className='btn btn-primary'>
            Sign Up
          </Link>
        </div>
        <div className='card px-4 my-4'>
          <Link to='sign-in' className='btn btn-secondary'>
            Sign In
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Home
