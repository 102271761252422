import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from './components/Navbar'
import PrivateRoute from './components/PrivateRoute'
import AddInterventionItem from './pages/AddInterventionItem'
import AddNewChart from './pages/AddNewChart'
import Chart from './pages/Chart'
import Charts from './pages/Charts'
import Dashboard from './pages/Dashboard'
import ForgotPassword from './pages/ForgotPassword'
import Home from './pages/Home'
import Profile from './pages/Profile'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'

function App() {
  return (
    <div className='appPage'>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/dashboard' element={<PrivateRoute />}>
            <Route path='/dashboard' element={<Dashboard />} />
          </Route>

          <Route path='/charts' element={<PrivateRoute />}>
            <Route path='/charts' element={<Charts />} />
          </Route>

          <Route path='/add-new-chart' element={<PrivateRoute />}>
            <Route path='/add-new-chart' element={<AddNewChart />} />
          </Route>

          <Route path='/chart/:chartId' element={<PrivateRoute />}>
            <Route path='/chart/:chartId' element={<Chart />} />
          </Route>

          <Route
            path='/add-intervention-item/:chartId'
            element={<PrivateRoute />}
          >
            <Route
              path='/add-intervention-item/:chartId'
              element={<AddInterventionItem />}
            />
          </Route>

          <Route path='/profile' element={<PrivateRoute />}>
            <Route path='/profile' element={<Profile />} />
          </Route>

          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
        </Routes>
        <Navbar />
      </Router>
      <ToastContainer />
    </div>
  )
}

export default App
