import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyAkxCDeHyj3s6-As36wmZhVoPLU2G67YLA',
  authDomain: 'callie-care-app-01.firebaseapp.com',
  projectId: 'callie-care-app-01',
  storageBucket: 'callie-care-app-01.appspot.com',
  messagingSenderId: '172379371371',
  appId: '1:172379371371:web:f477520d98d88f60febe2e'
}

// Initialize Firebase
initializeApp(firebaseConfig)
export const db = getFirestore()
