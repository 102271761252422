import {
  where,
  query,
  collection,
  orderBy,
  limit,
  getDocs
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as AddIcon } from '../assets/svg/addIcon.svg'
import LatestInterventions from '../components/LatestInterventions'
import Spinner from '../components/Spinner'
import { db } from '../firebase.config'

const Dashboard = () => {
  // TODO = create Daily todo's list context and CRUD Functionality
  // TODO = create MedList list context and CRUD Functionality
  const [loading, setLoading] = useState(true)
  const [todayChart, setTodayChart] = useState('')
  const [interventions, setInterventions] = useState(null)

  useEffect(() => {
    const fetchToday = async () => {
      try {
        // Get the Chart Day
        const chartRef = collection(db, 'charts')
        // const chartSnap = await getDocs(chartRef)

        // Create a query
        const q = query(chartRef, where('today', '==', true))

        // Exectute query
        const querySnap = await getDocs(q)

        querySnap.forEach(doc => {
          setTodayChart(doc.id)
        })
      } catch (error) {
        console.log(error)
      }
    }

    const fetchLatestInterventions = async () => {
      try {
        const interventionRef = collection(db, 'interventions')

        const q = query(interventionRef, orderBy('timestamp', 'desc'), limit(3))

        const querySnap = await getDocs(q)

        const interventions = []

        querySnap.forEach(doc => {
          return interventions.push({
            id: doc.id,
            data: doc.data()
          })
        })

        setInterventions(interventions)
      } catch (error) {
        console.log(error)
      }
    }

    fetchToday()
    fetchLatestInterventions()

    setLoading(false)
  }, [])

  console.log(interventions)

  if (loading) {
    return <Spinner />
  }

  return (
    <div className='container p-2 mb-24'>
      <header>
        <p className='pageHeader mt-5 text-center'>Dashboard</p>
      </header>

      <main className='mx-2 mb-2'>
        <div className='card p-2'>
          <Link to={`/chart/${todayChart}`} className='btn btn-primary'>
            Today's Chart
          </Link>
        </div>
        <div className='card bg-white pl-10 mb-4'>
          <h2 className='font-bold'>Today's todo's:</h2>
          <ul className='list-disc'>
            <li className='mb-2'>Ipratropium (5:30a/1:30p/9:30p)</li>
            {/* TODO ADD COUNTDOWN TIMER */}

            <li className='mb-2'>1 ml of MCT Oil (9a/Noon/6p)</li>
          </ul>
        </div>

        <div className='card bg-base-200 pl-1 mb-4'>
          <h2 className='pl-9 font-bold'>Latest Interventions</h2>

          {interventions && interventions.length > 0 ? (
            interventions.map(intervention => (
              <LatestInterventions
                key={intervention.id}
                id={intervention.id}
                intervention={intervention.data}
              />
            ))
          ) : (
            <>
              <h3>No Interventions to display</h3>
            </>
          )}
        </div>
        <div className='card bg-white pl-10 mb-4'>
          <h2 className='font-bold'>Med List</h2>
          <ul className='list-disc'>
            <li className='mb-2'>
              Ipratropium aka Atrovent
              <p className='text-sm'>1 Squirt each cheek 2/day and as needed</p>
            </li>

            <li className='mb-2'>
              Xopenex
              <p className='text-sm'>
                1 vial in Breathing Treatment every 4 hours as needed
              </p>
            </li>
          </ul>
        </div>

        <div className='card bg-white pl-10 mb-4'>
          <h2 className='font-bold'>Feeding Info</h2>
          <ul className='list-none'>
            <h3>
              <strong>Day</strong>{' '}
            </h3>
            <li className='mb-2'>
              <p>9a/Noon/3p/6p -></p>
              <p className='text-sm'>Dose: 110mL Rate: 220mL/hr</p>
            </li>

            <h3>
              <strong>Night</strong>{' '}
            </h3>
            <li className='mb-2'>
              <p>9p-7a Continuous -></p>
              <p className='text-sm'>Dose: 280mL Rate: 28mL/hr</p>
            </li>
          </ul>
        </div>
      </main>

      <div className='signInBar mx-4 mb-4'>
        <p className='signInText'>Add Intervention</p>
        <Link to={`/add-intervention-item/${todayChart}`}>
          <button className='signInButton'>
            <AddIcon fill='#ffffff' />
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Dashboard
