import { useEffect, useState } from 'react'
import {
  doc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore'
import { db } from '../firebase.config'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const AddNewChart = () => {
  const [todayChart, setTodayChart] = useState('')
  const [formData, setFormData] = useState({
    date: '',
    today: true
  })
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchToday = async () => {
      try {
        // Get the Chart Day
        const chartRef = collection(db, 'charts')
        // const chartSnap = await getDocs(chartRef)

        // Create a query
        const q = query(chartRef, where('today', '==', true))

        // Exectute query
        const querySnap = await getDocs(q)

        querySnap.forEach(doc => {
          setTodayChart(doc.id)
        })
      } catch (error) {
        console.log(error)
      }
    }

    fetchToday()

    setLoading(false)
  }, [])

  if (loading) {
    return <Spinner />
  }

  const { date } = formData

  const onSubmit = async e => {
    e.preventDefault()

    setLoading(true)

    let newChartId = ''

    try {
      const formDataCopy = {
        ...formData,
        date,
        timestamp: serverTimestamp()
      }

      const docRef = await addDoc(collection(db, 'charts'), formDataCopy)

      newChartId = docRef.id

      const todayRef = await doc(db, 'charts', todayChart)

      await updateDoc(todayRef, {
        today: false
      })
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
    toast.success('Chart Added!')
    navigate(`/chart/${newChartId}`)
  }

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }

  return (
    <div className='container p-2'>
      <header className='pageHeader text-center m-5'>
        <p className='pageHeader'>Add a New Chart</p>
      </header>

      <main>
        <div className='mx-10'>
          <p>Select a Date and Click the button to create a new chart:</p>
        </div>

        <div>
          <form onSubmit={onSubmit}>
            <div className='px-4 card bg-base-200 my-4'>
              <div className='form-control'>
                <label className='label'>
                  <span className='label-text'>New Chart Date</span>
                </label>
                <input
                  className='input input-bordered'
                  id='date'
                  type='date'
                  // value={date}
                  onChange={onChange}
                  placeholder='Add notes here...'
                />
              </div>
            </div>

            <div className='card px-4 mb-10'>
              <button className='btn  btn-primary'>Add New Chart</button>
            </div>
          </form>
        </div>
      </main>
    </div>
  )
}

export default AddNewChart
