import { useState, useEffect, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'

const AddInterventionItem = () => {
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    notes: '',
    images: [],
    time: ''
  })

  const { name, description, notes, images, time } = formData

  const auth = getAuth()
  const navigate = useNavigate()
  const isMounted = useRef(true)
  const params = useParams()

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, user => {
        if (user) {
          setFormData({
            ...formData,
            userRef: user.uid,
            chartRef: params.chartId
          })
        } else {
          navigate('/sign-in')
        }
      })
    }

    return () => {
      isMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted])

  if (loading) {
    return <Spinner />
  }

  const onSubmit = async e => {
    e.preventDefault()

    setLoading(true)

    if (images.length > 1) {
      setLoading(false)
      toast.error('Max 1 images')
      return
    }

    // Store images in firebase
    if (images.length === 1) {
      const storeImage = async image => {
        return new Promise((resolve, reject) => {
          const storage = getStorage()
          const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`

          const storageRef = ref(storage, 'images/' + fileName)

          const uploadTask = uploadBytesResumable(storageRef, image)

          uploadTask.on(
            'state_changed',
            snapshot => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused')
                  break
                case 'running':
                  console.log('Upload is running')
                  break
                default:
                  break
              }
            },
            error => {
              reject(error)
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                resolve(downloadURL)
              })
            }
          )
        })
      }

      const imgUrls = await Promise.all(
        [...images].map(image => storeImage(image))
      ).catch(() => {
        setLoading(false)
        toast.error('Images not uploaded')
        return
      })

      const formDataCopy = {
        ...formData,
        imgUrls,
        timestamp: serverTimestamp()
      }

      delete formDataCopy.images

      const docRef = await addDoc(collection(db, 'interventions'), formDataCopy)
      console.log(docRef)
    }

    if (images.length < 1) {
      const formDataCopy = {
        ...formData,
        timestamp: serverTimestamp()
      }

      delete formDataCopy.images

      const docRef = await addDoc(collection(db, 'interventions'), formDataCopy)
      console.log(docRef)
    }

    setLoading(false)
    toast.success('Intervention Saved!')
    navigate(`/chart/${params.chartId}`)
  }

  const onMutate = e => {
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }

    // Files
    if (e.target.files) {
      setFormData(prevState => ({
        ...prevState,
        images: e.target.files
      }))
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData(prevState => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  return (
    <div className='container p-2 mb-24 overflow-y-scoll'>
      <header>
        <p className='pageHeader text-center my-2'>Add Intervention</p>
      </header>

      <main>
        <form onSubmit={onSubmit}>
          <div className='px-10 card bg-base-200'>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>
                  Intervention Name{' '}
                  <p>
                    <small>Medication, Suction, Feeding, Diaper...</small>
                  </p>
                </span>
              </label>

              <input
                type='text'
                id='name'
                value={name}
                onChange={onMutate}
                placeholder='What did you do?'
                className='input'
                required
              />
            </div>
          </div>
          <div className='px-10 card bg-base-200'>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>
                  Description{' '}
                  <p>
                    <small>Xopenex, Day Feed, Dirty Diaper...</small>
                  </p>
                </span>
              </label>

              <input
                type='text'
                id='description'
                value={description}
                onChange={onMutate}
                placeholder='Day Feed 110ml...'
                className='input'
                required
              />
            </div>
          </div>
          <div className='px-10 card bg-base-200'>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Notes</span>
              </label>
              <textarea
                className='textarea h-24'
                id='notes'
                value={notes}
                onChange={onMutate}
                cols='30'
                rows='10'
                placeholder='Add notes here...'
              />
            </div>
          </div>

          <div className='form-control px-10 mb-4'>
            <label className='label'>
              <span className='label-text'>Time</span>
            </label>
            <input
              type='time'
              id='time'
              className='input input-bordered'
              onChange={onMutate}
              value={time}
            />
          </div>

          <div className='px-10 card bg-base-200'>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>Upload Image</span>
              </label>
              <input
                type='file'
                className='formInputFile'
                id='images'
                onChange={onMutate}
                max='1'
                accept='.jpg,.png,.jpeg'
                multiple
              />
            </div>
          </div>

          <div className='card p-10'>
            <button className='btn btn-primary'>Add Intervention</button>
          </div>
        </form>
      </main>
    </div>
  )
}

export default AddInterventionItem
