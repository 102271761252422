import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as NotepadIcon } from '../assets/svg/notepadIcon.svg'
import { ReactComponent as DashboardIcon } from '../assets/svg/dashboardIcon.svg'
import { ReactComponent as PersonOutlineIcon } from '../assets/svg/personOutlineIcon.svg'

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const pathMatchRoute = route => {
    if (route === location.pathname) {
      return true
    }
  }

  return (
    <footer className='navbarBar'>
      <nav className='navbarNav'>
        <ul className='navbarListItems'>
          <li className='navbarListItem' onClick={() => navigate('/dashboard')}>
            <DashboardIcon
              fill={pathMatchRoute('/dashboard') ? '#AE4770' : '#ffffff'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/dashboard')
                  ? 'navbarListItemNameActive'
                  : 'navbarListItemName'
              }
            >
              Dashboard
            </p>
          </li>
          <li className='navbarListItem' onClick={() => navigate('/charts')}>
            <NotepadIcon
              fill={pathMatchRoute('/charts') ? '#AE4770' : '#ffffff'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/charts')
                  ? 'navbarListItemNameActive'
                  : 'navbarListItemName'
              }
            >
              Charts
            </p>
          </li>
          <li className='navbarListItem' onClick={() => navigate('/profile')}>
            <PersonOutlineIcon
              fill={pathMatchRoute('/profile') ? '#AE4770' : '#ffffff'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/profile')
                  ? 'navbarListItemNameActive'
                  : 'navbarListItemName'
              }
            >
              Profile
            </p>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Navbar
