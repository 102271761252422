import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
// import { ReactComponent as DeleteIcon } from '../assets/svg/deleteIcon.svg'

const ChartItem = ({ chart, id }) => {
  const dt = DateTime.fromISO(chart.date)

  return (
    <div className='card mx-4 my-4 px-4 bg-white'>
      <li className='chartItem'>
        <Link to={`/chart/${id}`} className='chartItemLink'>
          {chart.imgUrls && chart.imgUrls.length > 0 && (
            <img
              src={chart.imgUrls[0]}
              alt={chart.date}
              className='chartItemImg'
            />
          )}
          <div className='chartItemDetails'>
            <p className='chartItemDay'>
              {/* {new Date(chart.day.seconds * 1000).toLocaleDateString('en-US')} */}
              {dt.toLocaleString(DateTime.DATE_MED)}
            </p>
            {/* <p className='chartItemType'>Last Intervention: {chart.day}</p> */}
          </div>
        </Link>
      </li>
    </div>
  )
}

export default ChartItem
